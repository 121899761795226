.product_cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  img {
    width: 40%;
    max-height: 30vh;
    padding: 0;

    @media screen and (min-width: 993px) {
      width: 20%;
    }
  }

  h3 {
    margin-top: 20px;
    text-align: center;
  }

  &__incrementors {
    display: flex;
    min-width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    @media screen and (min-width: 993px) {
      min-width: 20%;
    }

    >p {
      margin: 0;
    }
  }
}
