.tabs {
    >.tab {
        >a {
            display: inline-flex;
            justify-content: center;

            >img {
                height: 100%;
                margin-right: 20px;
            }
        }
    }
}