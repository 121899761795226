.product_list {
  .row {
    display: flex;
  }

  .row:last-child {
    margin-bottom: unset;

    .col:last-child {
      margin-bottom: unset;
    }
  }

  @media (max-width: 992px) {
    .row {
      flex-wrap: wrap;
    }

    .row > .col:first-child {
      margin-bottom: 20px;
    }

    .col {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.product_advert {
  text-align: center;
  border: solid 1px black;
  height: 100%;

  img {
    max-width: 250px;
    max-height: 250px;
  }
}
