@import '~materialize-css/sass/materialize';

.container {
  text-align: center;
  margin-top: 80px;
  margin-bottom: 50px;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 40px;
  }

  button {
    font-size: 1.2rem;
  }
}

.image {
  /* Your image styles here */
}

.loading {
  /* Your loading state styles here */
}

.error {
  /* Your error state styles here */
}

footer {
  background-color: paleturquoise;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: dotted 1px red;
  border-bottom: dotted 1px red;
  @media screen and (min-width: 993px) {
    flex-direction: row;
    padding-top: unset;
    padding-bottom: unset;
  }

  button:link {
    color: red;
  }
  button:hover {
    color: orange;
  }

  .business_logo {
    display: flex;
    justify-content: center;
    height: 150px;
  }

  .business_details {
    img {
      max-height: 150px;
    }
  }
}

html,
body {
  margin: 0;
}

#root {
  overflow: hidden;
}

header > nav > .tabs.tabs-fixed-width {
  display: none;
  height: inherit;
  align-items: center;

  @media screen and (min-width: 993px) {
    display: flex;
  }
}

.sidenav {
  li {
    display: flex;
    justify-content: center;

    > a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;
    }
    
    > button {

      span {
        margin-right: 10px;
      }
    }

    >.divider {
      margin: 0;
      border-bottom: 1px grey solid;
      width: 80%;
    }
  }

  .subheader {
    margin-top: 0;
  }
}

.btn {
  margin-top: 10px;
  margin-bottom: 15px;
}

.product_page_prompt {
  text-align: center;
}

.logo {
  min-height: 100px;
  .background {
    text-align: center;
    img {
      min-height: 100px;
      max-width: 100%;
    }
  }
}

.order_number {
  position: absolute;
  margin: 0;
  bottom: 10px;
  left: 20px;
  font-size: 20px;
}

.nav-wrapper {
  @media screen and (min-width: 993px) {
    display: none;
  }
}

.interactable {
  cursor: pointer;
}

@import './header';
@import './components/product_advert';
@import './components/product_cart';
@import './helpers/_helpers';
